import React from 'react';
import TypeIt from 'typeit';
import { Reveal } from 'react-reveal';
import styled, { createGlobalStyle } from 'styled-components';

const Wrap = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 80px auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  z-index: 1;
  position: relative;
  @media screen and (min-width: 1000px) {
    transform: translateX(-200px);
  }
`;

const GlobalStyle = createGlobalStyle`
  .fadeInFlicker {
    animation: glitch;
    @keyframes glitch {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      11% {
        opacity: 1;
      }
      12% {
        opacity: 0;
      }
      29% {
        opacity: 0;
        transform: translateX(-5%) translateY(-5%);
      }
      30% {
        opacity: 0.6;
        transform: translateX(-5%) translateY(-5%);
      }
      31% {
        opacity: 0;
        transform: translateX(-5%) translateY(-5%);
      }
      31.1% {
        transform: translateX(5%) translateY(5%);
      }
      32% {
        opacity: 1;
        transform: translateX(5%) translateY(5%);
      }
      33% {
        opacity: 0.6;
        transform: translateX(5%) translateY(5%);
      }
      34% {
        opacity: 1;
        transform: none;
      }
      35% {
        opacity: 0;
      }
      59% {
        opacity: 0;
      }
      60% {
        opacity: 0.6;
      }
      61% {
        opacity: 0;
      }
      62% {
        opacity: 1;
        transform: none;
      }
      62.1% {
        transform: translateX(3%) translateY(-8%);
      }
      63% {
        opacity: 0.6;
        transform: translateX(3%) translateY(-8%);
      }
      63.9% {
        transform: none;
      }
      64% {
        opacity: 1;
        transform: none;
      }
      80% {
        opacity: 1;
      }
      81% {
        opacity: 0;
      }
      89% {
        opacity: 0;
      }
      90% {
        opacity: 0.6;
      }
      91% {
        opacity: 0;
      }
      92% {
        opacity: 1;
      }
      93% {
        opacity: 0.6;
      }
      94% {
        opacity: 1;
      }
    }
  }
`;

class Type extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-new
    new TypeIt(this.el, this.props).go();
  }

  render() {
    return (
      <span
        ref={(el) => {
          this.el = el;
        }}
      >
        {this.props.children}
      </span>
    );
  }
}

const TopBar = styled.p`
  text-align: center;
  color: #525252;
  padding: 2px 0;
  margin: 0;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  font-size: 12px;
  border: 1px solid #cccccc;
  border-bottom: none;

  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  background: #f7f7f7; /* Old browsers */
  background: -moz-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f7f7f7),
    color-stop(100%, #b8b8b8)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* IE10+ */
  background: linear-gradient(to bottom, #f7f7f7 0%, #b8b8b8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#B8B8B8',GradientType=0 ); /* IE6-9 */
`;

const Shell = styled.ul`
  margin: 0;
  padding: 5px;
  list-style: none;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font: 14px 'Andale Mono', Consolas, 'Courier New';
  line-height: 1.6em;
  min-height: 400px;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  li {
    word-wrap: break-word;
    position: relative;
    padding: 0 0 0 15px;
    &:before {
      content: '$';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export default () => (
  <Reveal effect="fadeInFlicker" duration={3000} delay={0}>
    <GlobalStyle />
    <Wrap>
      <TopBar>192.168.12.122@angstfabriek.nl</TopBar>
      <Shell>
        <li>
          <Type
            strings="Hee jij daar!<br/>Er is niet veel tijd.<br/>Dit is de Tegenbeweging.<br/>Wees waakzaam en vertrouw niemand.<br/>Maar speel hun spel gewoon mee!<br/>We zitten in hun netwerk. Tussen de mensen. Binnen de kabels. In de wifi.<br/>Ga juist naar de Angstfabriek maar wees op je hoede.<br/>We sturen iemand en we zien je daar.<br/><br/> () ()<br/>('.')<br/>(u u)o"
            loop={false}
            speed={70}
            waitUntilVisible
            lifeLike
            startDelay={3300}
          />
        </li>
      </Shell>
    </Wrap>
  </Reveal>
);
