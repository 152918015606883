import React, { Component } from 'react';
import styled from 'styled-components';

import poster1 from '../img/poster1.jpg';
import poster2 from '../img/poster2.jpg';
import poster3 from '../img/poster3.jpg';
import poster4 from '../img/poster4.jpg';
import posterBackground from '../img/posterbackground.jpg';

const posters = [poster1, poster2, poster3, poster4];

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
  background-image: url(${posterBackground});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 20px;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
`;

const InnerContainer = styled.div`
  height: auto;
  overflow-y: visible;
  white-space: nowrap;
  transform: translateX(${({ items, position }) => `-${(100 / items.length) * position}%`});
  transition: all 1s linear;
  @media screen and (max-width: 900px) {
    width: 100%;
    transform: translateX(${({ position }) => `-${100 * position}%`});
  }
`;

const Poster = styled.img`
  display: inline-block;
  width: 33%;
  margin: 0px 60px;
  position: relative;
  animation: poster-glitch;
  animation-duration: 8s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0;
    &:first-child {
      margin-left: 0;
    }
  }
  @keyframes poster-glitch {
    0% {
      transform: translateX(2%) translateY(-5%);
    }
    0.2% {
      transform: translateX(4%) translateY(-8%);
    }
    0.4% {
      transform: translateX(-1%) translateY(4%);
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    0.5% {
      -webkit-clip-path: none;
      clip-path: none;
    }
    0.6% {
      transform: translateX(-8%) translateY(6%);
    }
    0.8% {
      transform: translateY(-3%);
    }
    1% {
      transform: none;
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    2% {
      -webkit-clip-path: none;
      clip-path: none;
    }
  }
`;

export default class PosterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
    };
  }
  componentDidMount() {
    setInterval(() => {
      this.setState({
        position: this.state.position < posters.length - 1 ? this.state.position + 1 : 0,
      });
    }, 3000);
  }
  render() {
    const { position } = this.state;
    return (
      <Wrapper>
        <OuterContainer>
          <InnerContainer items={posters} position={position}>
            {posters.map(x => (
              <Poster src={x} />
            ))}
          </InnerContainer>
        </OuterContainer>
      </Wrapper>
    );
  }
}
