import React from 'react';
import styled from 'styled-components';
import Button from './Buttons';

const TicketsButton = styled(Button)`
  display: block;
  width: 100%;
  background-image: none;
  margin-top: 20px;
  &:after {
    display: none;
  }
  &:hover {
    color: #000;
    background-color: #fff;
  }
  &:active {
    background-image: none;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

const Container = styled.div`
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
  width: 35%;
  max-width: 400px;
  position: absolute;
  height: auto;
  padding: 20px;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 900px) {
    top: initial;
    bottom: 70px;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #fff;
  font-size: 28px;
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
`;

const Event = styled.div`
  border-top: 1px solid #999;
  padding: 5px 0;
`;

const Date = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 20px;
  font-weight: bold;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Location = styled.div`
  color: #999;
  font-size: 14px;
  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
`;

export default () => {
  let isIE = null;
  if (typeof document !== 'undefined') {
    isIE = /*@cc_on!@*/ false || !!document.documentMode;
  }
  return (
    <Container>
      <Title>Data &amp; Tickets</Title>
      <Event>
        <Date>12 APRIL T/M 13 JULI</Date>
        <Location>Utrecht: RAUM (Berlijnplein 520)</Location>
      </Event>
      <Event>
        <Date>21 AUGUSTUS T/M 2 NOV</Date>
        <Location>Eindhoven: Ketelhuisplein, Strijp S</Location>
      </Event>
      <Event>
        <Date>11 NOV T/M 1 FEB</Date>
        <Location>Den Haag: Huijgenspark (bij Holland Spoor)</Location>
      </Event>
      <TicketsButton
        buttonText="BESTEL TICKETS"
        href={
          isIE ? 'https://tickets.voordemensen.nl/angstfabriek/cal' : '/tickets'
        }
      />
    </Container>
  );
};
