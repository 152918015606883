import styled from 'styled-components';
import handleViewport from 'react-in-viewport';
import React, { useRef, useEffect, useState } from 'react';
import { AudioOn, AudioOff } from './icons';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

const StyledVideo = styled.video`
  display: block;
  width: 100%;
`;

const MuteIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  z-index: 10;
  svg {
    filter: drop-shadow(0 0 5px #000);
    path {
      fill: #fff;
    }
  }
`;

const Video = ({
  autoplay = true,
  enterCount,
  forwardedRef,
  inViewport,
  muted: _muted = true,
  urls,
  ...props
}) => {
  const [muted, setMuted] = useState(_muted);
  const video = useRef(null);
  const playVideo = () => {
    video.current.play();
  };

  useEffect(() => {
    video.current.addEventListener(
      'volumechange',
      e => {
        if (e.target.volume === 0 || e.target.muted) {
          return setMuted(true);
        }
        return setMuted(false);
      },
      []
    );
  });

  if (inViewport && enterCount === 1) {
    setTimeout(playVideo, 3000);
  }

  const mapUrls = () =>
    urls.map(x => <source key={x.id} src={x.src} type={x.type} />);

  return (
    <Wrapper ref={forwardedRef}>
      <StyledVideo muted controls loop {...props} ref={video}>
        {mapUrls()}
      </StyledVideo>
      {autoplay ? (
        <MuteIcon
          onClick={() => {
            video.current.muted = !video.current.muted;
          }}
        >
          {muted ? <AudioOff /> : <AudioOn />}
        </MuteIcon>
      ) : null}
    </Wrapper>
  );
};

export default handleViewport(Video);
