import styled from 'styled-components';
import eyesBackground from '../img/pattern-eyes.png';

export default styled.div`
  position: relative;
  margin-top: ${({ isHome }) => (isHome ? 'calc(100vh - 100px)' : '')};
  background-color: #000;
  width: 100vw;
  background-image: url(${eyesBackground});
  background-repeat: repeat-x repeat-y;
  background-position: 0 0;
  background-size: 200px auto;
  height: auto;
  padding: 0 0 100px 0;
  background-attachment: fixed;
  min-height: 100vh;
  @media screen and (max-width: 900px) {
    margin-top: ${({ isHome }) => (isHome ? 'calc(100vh - 50px)' : '')};
  }
`;
