import React from 'react';
import LandingSection from '../../../components/LandingSection';
import PageWrapper from '../../../components/PageWrapper';
import IntroSection from '../../../components/IntroSection';
import CTASection from '../../../components/CTASection';
import TestimonialSection from '../../../components/TestimonialSection';
import PosterSection from '../../../components/PosterSection';
import Navigation, {
  GhostContainer
} from '../../../@wingscms/hummingbird/components/Navigation';
import Footer from '../../../@wingscms/hummingbird/components/Footer';

const Content = props => {
  const {
    pageContext: {
      node: {
        menu: { items }
      },
      shareUrls
    }
  } = props;
  return (
    <PageWrapper isHome style={{ paddingBottom: 0 }}>
      <GhostContainer />
      <Navigation items={items} shareUrls={shareUrls} />
      <IntroSection />
      <CTASection />
      <TestimonialSection />
      <PosterSection />
      <Footer />
    </PageWrapper>
  );
};

export default props => (
  <div>
    <LandingSection />
    <Content {...props} />
  </div>
);
