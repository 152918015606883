import React from 'react';
import styled from 'styled-components';

import campaignImage from '../img/campaign_image.jpg';
import GlitchContainer from './GlitchContainer';
import Dates from './Dates';

const LandingSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export default () => (
  <LandingSection>
    <GlitchContainer glitchImage={campaignImage}>
      <div className="glitch glitch--style-1">
        <div className="glitch__img" />
        <div className="glitch__img" />
        <div className="glitch__img" />
        <div className="glitch__img" />
        <div className="glitch__img" />
        <div className="content">
          <p className="content__text">Critical Mass Presenteert</p>
          <h1 className="content__title">De Angstfabriek</h1>
          <p className="content__text2">
            {' '}
            ga undercover in een fake fear factory{' '}
          </p>
        </div>
        <Dates />
      </div>
    </GlitchContainer>
  </LandingSection>
);
