import React from 'react';
import styled from 'styled-components';
import TerminalWindow from './TerminalWindow';
import Video from './Video';
import testimonialBackground from '../img/testimonialbackground.jpg';
import testimonialTriangle from '../img/testimonialtriangle.svg';
import kim from '../img/kim.jpg';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

// const TestimonialContainer = styled.div`
//   margin-top: -300px;
//   padding: 300px 20px 20px 20px;
//   background-image: url(${testimonialBackground});
//   background-repeat: no-repeat;
//   background-size: cover;
//   width: 100%;
//   position: relative;
//   z-index: 0;
// `;

// const TestimonialText = styled.div`
//   width: 100%;
//   margin: 0 auto 62px auto;
//   padding: 20px;
//   max-width: 1000px;
//   background-color: #fff;
//   font-size: 30px;
//   text-transform: uppercase;
//   position: relative;
//   font-weight: normal;
//   font-family: 'Edmondsans';
//   &:after {
//     content: '';
//     width: 62px;
//     height: 62px;
//     position: absolute;
//     top: 100%;
//     left: 20%;
//     background-image: url(${testimonialTriangle});
//     background-repeat: no-repeat;
//   }
//   @media screen and (max-width: 900px) {
//     font-size: 18px;
//   }
// `;

// const TestimonialSource = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   max-width: 500px;
//   margin: 0 auto;
//   padding: 40px 0;
//   align-items: center;
//   > img {
//     width: 120px;
//     max-width: 120px;
//     min-width: 120px;
//     flex-basis: auto;
//     height: 120px;
//     border-radius: 100%;
//     margin: 0;
//   }
//   > div {
//     width: calc(100% - 120px);
//     max-width: calc(100% - 120px);
//     min-width: calc(100% - 120px);
//     flex-basis: auto;
//     padding: 0 0 0 40px;
//     color: #fff;
//     text-transform: uppercase;
//     font-size: 12px;
//     p {
//       margin: 0;
//     }
//   }
//   @media screen and (max-width: 500px) {
//     flex-direction: column;
//     > div {
//       padding-top: 20px;
//       width: 100%;
//       max-width: 100%;
//       min-width: 100%;
//     }
//   }
// `;

export default () => (
  <Wrapper>
    <TerminalWindow />
    <Video cloudName="hibqeex8x" id="v1574249398/Promos_Den_Haag_lang_d6mutr" />
    {/* <TestimonialContainer>
      <TestimonialText>
        Ik had echt álles geprobeerd, van propaganda op de staats-tv tot in scène gezette opstootjes
        bij de grens. Mensen bleven maar praten over klimaat en corruptie! Dankzij de slimme
        campagne van de Angstfabriek is veiligheid weer hét thema. Aanrader!
      </TestimonialText>
      <TestimonialSource>
        <img src={kim} alt="Kim" />
        <div>
          <p>
            Campagne: <strong>GRENZEN DICHT</strong>
          </p>
          <p>
            Klant: <strong>Ministerie van Waarheid</strong>
          </p>
          <p>
            Instrument: <strong>Alternative Facts, Dark Social</strong>
          </p>
        </div>
      </TestimonialSource>
    </TestimonialContainer> */}
  </Wrapper>
);
