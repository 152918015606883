import React from 'react';
import styled from 'styled-components';
import logo from '../img/logo.png';

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #ffed00;
  height: auto;
  flex-direction: row;
  align-items: center;
  height: auto;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 70%;
  background-color: #ffed00;
  color: #000;
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 20vw;
  line-height: 0.7;
  text-transform: uppercase;
  font-family: 'Edmondsans';
  cursor: crosshair;
  span {
    font-size: 14vw;
  }
  @media screen and (max-width: 900px) {
    padding: 30px 0;
    width: 100%;
  }
  animation: text-glitch;
  animation-duration: 8s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  @keyframes text-glitch {
    0% {
      color: pink;
    }
    0.2% {
      color: blue;
      transform: translateX(2%) translateY(-5%);
    }
    0.4% {
      color: cyan;
      transform: none;
    }
    0.5% {
      color: pink;
    }
    0.6% {
      color: red;
      transform: translateX(-8%) translateY(6%);
    }
    0.8% {
      color: black;
      transform: none;
    }
    1% {
      color: yellow;
    }
    2% {
      color: #000;
    }
    50% {
      transform: none;
    }
    50.1% {
      transform: translateX(-20%) translateY(-20%);
    }
    50.5% {
      transform: none;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 30%;
  padding: 20px;
  display: block;
  overflow: hidden;
  background-color: #fff;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  animation: logo-glitch;
  animation-duration: 8s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  cursor: not-allowed;
  @keyframes logo-glitch {
    0% {
      transform: translateX(2%) translateY(-5%);
    }
    0.2% {
      transform: translateX(4%) translateY(-8%);
    }
    0.4% {
      transform: translateX(-1%) translateY(4%);
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    0.5% {
      -webkit-clip-path: none;
      clip-path: none;
    }
    0.6% {
      transform: translateX(-8%) translateY(6%);
    }
    0.8% {
      transform: translateY(-3%);
    }
    1% {
      transform: none;
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    2% {
      -webkit-clip-path: none;
      clip-path: none;
    }
  }
`;

export default () => (
  <Container>
    <TextWrapper>
      ANGST
      <br />
      <span>IS GOED!</span>
    </TextWrapper>
    <LogoWrapper>
      <Logo src={logo} />
    </LogoWrapper>
  </Container>
);
