import React from 'react';
import styled from 'styled-components';
import { wide } from '@wingscms/crane';
import Video from './Video';
import CloudinaryVideoProvider from './CloudinaryVideoProvider';

const VideoWrapper = styled.div`
  ${wide};
  > * {
    display: block;
    width: 100%;
    width: 1160px;
    margin: 80px auto;
    @media screen and (max-width: 800px) {
      margin: 40px auto;
    }
  }
`;

export default props => (
  <VideoWrapper>
    <CloudinaryVideoProvider videoId={props.id} {...props}>
      <Video
        poster={`//res.cloudinary.com/${props.cloudName}/video/upload/vc_auto/${props.id}.jpg`}
      />
    </CloudinaryVideoProvider>
  </VideoWrapper>
);
