import React, { Component } from 'react';
import styled from 'styled-components';
import directeur from '../img/directeur.jpg';

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
  padding: 80px 20px;
`;

const HalfContainer = styled.div`
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  width: 50%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  > a {
    text-decoration: none;
  }
`;

const TextOne = styled.div`
  color: #000;
  font-family: 'Edmondsans', sans-serif;
  font-size: 3vw;
  position: relative;
  display: inline-block;
  z-index: 4;
  padding: 10px;
  transform: rotate(-2deg);
  margin: 80px 0 0 5%;
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  @media screen and (max-width: 900px) {
    font-size: 6vw;
  }
`;
const TextTwo = styled.div`
  color: #000;
  font-family: 'Edmondsans', sans-serif;
  text-transform: uppercase;
  font-size: 6vw;
  position: relative;
  display: inline-block;
  z-index: 1;
  margin: -5px 0 0 10%;
  padding: 10px;
  line-height: 1;
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #ffed00;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  @media screen and (max-width: 900px) {
    font-size: 12vw;
  }
`;

const TextThree = styled.div`
  color: #000;
  font-family: 'Edmondsans', sans-serif;
  text-transform: uppercase;
  font-size: 6vw;
  animation-delay: calc(6s + 4s * 0.25);
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 10px;
  margin: -10px 0 0 5%;
  line-height: 1;
  transform: rotate(2deg);
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #ffed00;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  @media screen and (max-width: 900px) {
    font-size: 12vw;
  }
`;

const CardContainer = styled.div`
  display: block;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  background-color: #ffed00;
  margin: 80px auto 0 auto;
  transition: 0.2s all ease-in-out;
  color: #000;
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    animation: card-glitch;
    animation-duration: 0.2s;
    @keyframes card-glitch {
      0% {
        transform: translateX(10%) translateY(-10%);
        background-color: cyan;
      }
      5% {
        transform: translateX(17%) translateY(-170%);
        background-color: pink;
      }
      15% {
        transform: translateX(-2%) translateY(10%);
        background-color: pink;
      }
      20% {
        transform: translateX(-12%) translateY(20%);
        background-color: silver;
      }
      25% {
        background-color: #ffed00;
        transform: none;
      }
    }
  }
  > img {
    width: 100%;
    height: auto;
    margin: 0;
  }
  > p {
    padding: 20px;
  }
`;

export default class PosterSection extends Component {
  render() {
    return (
      <Wrapper>
        <HalfContainer>
          <TextOne>Bange mensen spelen op safe</TextOne>
          <br />
          <TextTwo>EN VEILIG</TextTwo>
          <br />
          <TextThree>IS BETER</TextThree>
        </HalfContainer>
        <HalfContainer>
          <CardContainer
            onClick={(e) => {
              e.preventDefault();
              // eslint-disable-next-line no-undef
              vdm_calendar();
            }}
          >
            <img src={directeur} alt="" />
            <p>
              Zijn jouw burgers of consumenten nog niet bewust genoeg van alle gevaren? Kom langs
              bij de Angstfabriek voor een vrijblijvende demonstratie.
              <br />
              <br />
              BOEK RONDLEIDING
            </p>
          </CardContainer>
        </HalfContainer>
      </Wrapper>
    );
  }
}
